import axios from "axios";
import {API_URL, PER_PAGE} from "../../../configs/constants";
import humps from "humps";
import {setFetchMessage} from "../notification";
import {getErrorMessage, isError} from "../../../utility/helpers";

export const GET_MANAGE_DATA = "GET_MANAGE_DATA";
export const GET_MANAGE_DATA_SUCCESS = "GET_MANAGE_DATA_SUCCESS";
export const GET_MANAGE_DATA_FAIL = "GET_MANAGE_DATA_FAIL";
export const ADD_MANAGE_ITEM = "ADD_MANAGE_ITEM";
export const ADD_MANAGE_ITEM_SUCCESS = "ADD_MANAGE_ITEM_SUCCESS";
export const ADD_MANAGE_ITEM_FAIL = "ADD_MANAGE_ITEM_FAIL";
export const UPDATE_MANAGE_ITEM = "UPDATE_MANAGE_ITEM";
export const UPDATE_MANAGE_ITEM_SUCCESS = "UPDATE_MANAGE_ITEM_SUCCESS";
export const UPDATE_MANAGE_ITEM_FAIL = "UPDATE_MANAGE_ITEM_FAIL";
export const DELETE_MANAGE_ITEM = "DELETE_MANAGE_ITEM";
export const DELETE_MANAGE_ITEM_SUCCESS = "DELETE_MANAGE_ITEM_SUCCESS";
export const DELETE_MANAGE_ITEM_FAIL = "DELETE_MANAGE_ITEM_FAIL";
export const BUY_PROXY = "BUY_PROXY";
export const BUY_PROXY_FAIL = "BUY_PROXY_FAIL";
export const IMPORT_PROXIES = "IMPORT_PROXIES";
export const IMPORT_PROXIES_SUCCESS = "IMPORT_PROXIES_SUCCESS";
export const IMPORT_PROXIES_FAIL = "IMPORT_PROXIES_FAIL";
export const GET_MAIL_STATUS = "GET_MAIL_STATUS";
export const GET_MAIL_STATUS_SUCCESS = "GET_MAIL_STATUS_SUCCESS";
export const GET_MAIL_STATUS_FAIL = "GET_MAIL_STATUS_FAIL";
export const EXPORT_CODE = "EXPORT_CODE";
export const EXPORT_CODE_SUCCESS = "EXPORT_CODE_SUCCESS";
export const EXPORT_CODE_FAIL = "EXPORT_CODE_FAIL";

export const getMailStatus = () => {
  let payload = {name: 'mailStatus'};
  return async dispatch => {
    dispatch({type: GET_MAIL_STATUS, payload});

    try {
      const response = await axios.get(`${API_URL}/api/amazon-locker-code/gmail/status`);
      isError(response);

      dispatch({
        type: GET_MAIL_STATUS_SUCCESS,
        data: humps.camelizeKeys(response.data),
        payload
      });
    } catch (error) {
      dispatch({type: GET_MAIL_STATUS_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const exportCode = (data) => {
  return async dispatch => {
    dispatch({type: EXPORT_CODE});
    try {
      const response = await axios.post(`${API_URL}/api/amazon-locker-code/export`, data);
      isError(response);
      dispatch({
        type: EXPORT_CODE_SUCCESS,
        data: humps.camelizeKeys(response.data),
      });
      return response;
    } catch (error) {
      dispatch({type: EXPORT_CODE_SUCCESS});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
const getManageData = ({path, params = {}, payload}) => {

  return async dispatch => {
    dispatch({type: GET_MANAGE_DATA, payload});

    try {
      const response = await axios[['lockersCodeExported', 'lockersCode'].includes(payload.name) ? 'post' : 'get'](`${API_URL}/api/${path}list`, ['lockersCodeExported', 'lockersCode'].includes(payload.name) ? params : {params});
      isError(response);
      let {pagination} = response.data;
      if (pagination) {
        let {data, ...rest} = pagination;
        payload.pagination = humps.camelizeKeys(rest);
      }
      dispatch({
        type: GET_MANAGE_DATA_SUCCESS,
        data: humps.camelizeKeys(pagination ? pagination.data : response.data[payload.name]),
        payload
      });
    } catch (error) {
      dispatch({type: GET_MANAGE_DATA_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

const addManageItem = ({path, data, payload}) => {
  return async dispatch => {
    dispatch({type: ADD_MANAGE_ITEM, payload});
    try {
      const response = await axios.post(`${API_URL}/api/manage/${path}`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: ADD_MANAGE_ITEM_SUCCESS,
        data: humps.camelizeKeys(response.data[payload.type]),
        payload
      });
      return response
    } catch (error) {
      dispatch({type: ADD_MANAGE_ITEM_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const updateMangeItem = ({path, data, payload}) => {
  return async dispatch => {
    dispatch({type: UPDATE_MANAGE_ITEM, payload});
    try {
      const response = await axios.patch(`${API_URL}/api/manage/${path}/update/${payload.id}`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: UPDATE_MANAGE_ITEM_SUCCESS,
        data: humps.camelizeKeys(response.data[payload.type]),
        payload
      });
      return response
    } catch (error) {
      dispatch({type: UPDATE_MANAGE_ITEM_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

const deleteManageItem = ({path, payload}) => {
  return async dispatch => {
    dispatch({type: DELETE_MANAGE_ITEM, payload});
    try {
      const response = await axios.delete(`${API_URL}/api/manage/${path}/${payload.id}`);
      isError(response);
      dispatch({
        type: DELETE_MANAGE_ITEM_SUCCESS,
        payload
      });
      return response
    } catch (error) {
      dispatch({type: DELETE_MANAGE_ITEM_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getProfiles = (params) => getManageData({path: 'manage/profiles/', params, payload: {name: 'profiles'}});
export const getProxies = (params) => getManageData({path: 'manage/proxy/', params, payload: {name: 'proxies'}});
export const getWishlist = (params) => getManageData({path: 'manage/wishlist/', params, payload: {name: 'wishlist'}});
export const getCodes = (params) => getManageData({path: 'manage/codes/', params, payload: {name: 'codes'}});
export const getDomains = (params) => getManageData({path: 'manage/domain/', params, payload: {name: 'domains'}});
export const getLockers = (params) => getManageData({
  path: 'manage/amazon-hub-lockers/',
  params,
  payload: {name: 'lockers'}
});
export const getLockersCode = (params) => getManageData({
  path: 'amazon-locker-code/',
  params,
  payload: {name: 'lockersCode'}
});
export const getLockersCodeExported = (params) => getManageData({
  path: 'amazon-locker-code/export/',
  params,
  payload: {name: 'lockersCodeExported'}
});
export const getTesterAddresses = (params) => getManageData({
  path: 'manage/tester-address/available-',
  params,
  payload: {name: 'testerAddress'}
});

export const addProxy = (data) => addManageItem({path: 'add-proxy', data, payload: {name: 'proxy'}});
export const addLocker = (data) => addManageItem({
  path: 'amazon-hub-lockers/add',
  data,
  payload: {name: 'lockers', type: 'locker'}
});
export const addWishlist = (data) => addManageItem({
  path: 'add-wishlist',
  data,
  payload: {name: 'wishlist', type: 'wishlist'}
});
export const addDomain = (data) => addManageItem({
  path: 'add-domain',
  data,
  payload: {name: 'domains', type: 'domain'}
});
export const addTesterAddress = (data) => addManageItem({
  path: 'tester-address/add',
  data,
  payload: {name: 'testerAddress', type: 'tester_address'}
});

export const deleteLocker = (id) => deleteManageItem({
  path: 'amazon-hub-lockers/delete',
  payload: {name: 'lockers', id}
});
export const deleteWishlist = (id) => deleteManageItem({path: 'wishlist', payload: {name: 'wishlist', id}});
export const deleteDomain = (id) => deleteManageItem({path: 'domain', payload: {name: 'domains', id}});
export const deleteTesterAddress = (id) => deleteManageItem({
  path: 'tester-address/delete',
  payload: {name: 'testerAddress', id}
});

export const updateLocker = (data, id) => updateMangeItem({
  path: 'amazon-hub-lockers',
  data,
  payload: {id, name: 'lockers', type: 'locker'}
});
export const updateTesterAddress = (data, id) => updateMangeItem({
  path: 'tester-address',
  data,
  payload: {id, name: 'testerAddress', type: 'tester_address'}
});

export const buyProxy = (data) => {
  return async dispatch => {
    dispatch({type: BUY_PROXY});
    try {
      const response = await axios.post(`${API_URL}/api/manage/buy-proxies`, data);
      isError(response);
      dispatch(getProxies({
          page: 1,
          perPage: PER_PAGE,
        })
      );
      return response
    } catch (error) {
      dispatch({type: BUY_PROXY_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const importProxies = (data) => {
  return async dispatch => {
    dispatch({type: IMPORT_PROXIES});
    try {
      const response = await axios.post(`${API_URL}/api/manage/proxy/import`, {proxies: humps.decamelizeKeys(data)});
      isError(response);
      dispatch(getProxies({
          page: 1,
          perPage: PER_PAGE,
        })
      );
      return response
    } catch (error) {
      dispatch({type: IMPORT_PROXIES_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

