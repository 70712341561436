import React, {Suspense, lazy} from "react";
import ReactDOM from "react-dom";
import Favicon from 'react-favicon'
import {Provider} from "react-redux";
import {Layout} from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import {store} from "./redux/storeConfig/store";
import Spinner from "./components/spinner/Fallback-spinner";
import "./index.scss";
import axios from "axios";
import {ToastContainer} from "react-toastify";
import {isLocalhost} from "./utility/helpers";

const LazyApp = lazy(() => import("./App"));

const isProduction = process.env.REACT_APP_DEPLOY === 'production';


// configureDatabase()

if (isLocalhost) {
  const user = localStorage.getItem('rmsUser') && JSON.parse(localStorage.getItem('rmsUser'));
  if (user) {
    axios.defaults.headers.common = {Authorization: `Bearer ${user?.token}`}
  }
} else {
  axios.defaults.withCredentials = true;
  axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
  }
  const user = localStorage.getItem('rmsUser', null) && JSON.parse(localStorage.getItem('rmsUser'));
  if (user) {
    axios.defaults.headers.common = {Authorization: `Bearer ${user?.token}`}
  }
}
ReactDOM.render(
  <Provider store={store}>
    <Favicon url={isProduction ? require('./assets/img/favicon.png') : require('./assets/img/favicon-dev.ico')}/>
    <Suspense fallback={<Spinner/>}>
      <Layout>
        {/*<IntlProviderWrapper>*/}
        <LazyApp/>
        <ToastContainer pauseOnFocusLoss={false}/>
        {/*</IntlProviderWrapper>*/}
      </Layout>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
