import Cookies from 'js-cookie';
import axios from "axios"
import {LOGIN_WITH_JWT_SUCCESS, LOGOUT_WITH_JWT} from "../actions/auth/loginActions";
import {isLocalhost} from "../../utility/helpers";

export default function auth() {

  return ({dispatch, getState}) => {
    return next => action => {
      const isAuth = getState().auth.login.auth;
      const freshToken = Cookies.get('freshToken');
      // console.log(`${action.type} ==> isAuth: ${isAuth}, freshToken:${freshToken}, secondAction: ${action.secondAction}`);
      try {
        if (action.type === LOGIN_WITH_JWT_SUCCESS) {
          Cookies.set('freshToken', true, {expires: action.remember ? 30 : 1});
          localStorage.setItem('rmsUser', JSON.stringify({...action.data, role: action.data.roles[0] || {}}));
          // if (isLocalhost) {
            axios.defaults.headers.common = {Authorization: `Bearer ${action.data.token}`}
          // }
        }

        if (action.type === LOGOUT_WITH_JWT) {
          Cookies.remove('freshToken');
          // Cookies.remove('_token');
          localStorage.removeItem('rmsUser');
          // if (isLocalhost) {
          axios.defaults.headers.common = {Authorization: ``}
          // }
        }

        if (isAuth) {
          if (!freshToken) {
            let token = getState().auth.login.user.token;
            Cookies.set('_token', token);
            Cookies.set('freshToken', true);
            // if (isLocalhost) {
              axios.defaults.headers.common = {Authorization: `Bearer ${token}`}
            // }
            dispatch({...action, secondAction: true})
          } else {
            if (action.error && ((action.error.status === 401) || (action.error.response && action.error.response.status === 401))) {
              let error = action.error.data ? action.error.data : action.error.response ? action.error.response.data : action.error;
              dispatch({type: LOGOUT_WITH_JWT, error});
            } else {
              next(action)
            }

          }
        } else {
          next(action)
        }
      } catch (err) {

        console.log("action catch==>", err);
      }

    };
  };
}
